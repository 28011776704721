<template>
  <div class="main-wrapper">
    <NavbarDashboard></NavbarDashboard>
    <SidebarDashboard></SidebarDashboard>
    <div class="main-content">
      <form @submit.prevent="onSubmit">
        <section class="section">
          <div class="d-flex justify-content-between align-items-center mb-3">
            <div class="">
              <h4 class="title">{{ labelPage }} Penjualan Aset</h4>
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item">
                    <router-link to="/data-asset-selling"
                      >Penjualan Aset</router-link
                    >
                  </li>
                  <li class="breadcrumb-item active" aria-current="page">
                    {{ labelPage }}
                  </li>
                </ol>
              </nav>
            </div>
            <div
              class="button-edit"
              v-show="validated == 1 && labelPage == 'Detail' && isDetailable"
            >
              <button class="btn-add" type="button" @click="editable">
                <img src="/img/icon-edit1.svg" alt="" style="width: 20px" />
                Detail
              </button>
            </div>
            <div
              v-show="validated != 1 || (labelPage == 'Tambah' && isDetailable)"
            >
              <button class="btn btn-save" :disabled="isSubmit">
                <span class="fas fa-spinner fa-spin" v-if="isSubmit"></span>
                Simpan
              </button>
            </div>
          </div>

          <div class="card-table info-detail">
            <div class="row mt-3">
              <div class="col-12">
                <div class="title">{{ labelPage }} Penjualan Aset</div>
                <div class="sub-title">
                  {{ labelPage }} informasi yang diperlukan
                </div>
              </div>
            </div>
            <hr />
            <div class="row">
              <div class="col-6">
                <div class="form-group">
                  <label>Aset :</label>
                  <Select2
                    v-if="labelPage == 'Tambah'"
                    :disabled="validated == 1 && labelPage == 'Detail'"
                    v-model="formData.kode_aset"
                    :options="OptionAset"
                    placeholder="Pilih Aset"
                    @change="formChange('kode_aset', $event)"
                    @select="formChange('kode_aset', $event)"
                    :class="{
                      'is-invalid': formError && formError.kode_aset,
                    }"
                  />
                  <input
                    v-else
                    type="text"
                    class="form-control"
                    v-model="formData.nama_aset"
                    disabled
                  />
                </div>

                <div class="form-group">
                  <label>Tanggal Jual :</label>
                  <Datepicker
                    :disabled="validated == 1 && labelPage == 'Detail'"
                    :autoApply="true"
                    :closeOnScroll="true"
                    v-model="formData.tanggal_jual"
                    placeholder="Tanggal Jual"
                    :format="format"
                    :enableTimePicker="false"
                    locale="id-ID"
                    selectText="Pilih"
                    cancelText="Batal"
                    :class="{
                      'is-invalid': formError && formError.tanggal_jual,
                    }"
                    @update:modelValue="changeDate($event, 'tanggal_jual')"
                  ></Datepicker>
                </div>

                <div class="form-group">
                  <label>Laba/Rugi Penghentian Aset :</label>
                  <Select2
                    :disabled="validated == 1 && labelPage == 'Detail'"
                    v-model="formData.akun_penghentian_aset"
                    :options="OptionAccountStopAset"
                    :settings="{ templateResult: formatState }"
                    placeholder="Pilih Laba/Rugi Penghentian Aset"
                    @change="formChange('akun_penghentian_aset')"
                    @select="formChange('akun_penghentian_aset')"
                    :class="{
                      'is-invalid':
                        formError && formError.akun_penghentian_aset,
                    }"
                  />
                </div>
              </div>

              <div class="col-6">
                <!-- <div class="form-group">
                  <label class="">Client</label>
                  <Select2
                    :disabled="validated == 1 && labelPage == 'Detail'"
                    v-model="formData.id_client"
                    :options="OptionClient"
                    placeholder="Pilih Client"
                    @change="formChange('id_client')"
                    @select="formChange('id_client')"
                    :class="{
                      'is-invalid': formError && formError.id_client,
                    }"
                  />
                </div> -->

                <div class="row">
                  <div class="form-group col-6" v-if="labelPage == 'Tambah'">
                    <label>Nilai Aset :</label>
                    <div class="input-group">
                      <div class="input-group-prepend">
                        <span class="input-group-text">Rp</span>
                      </div>
                      <input
                        type="text"
                        class="form-control text-right"
                        v-model.lazy="formData.aset_nilai"
                        disabled
                      />
                    </div>
                  </div>
                  <div
                    class="form-group"
                    :class="{
                      'col-6': labelPage == 'Tambah',
                      'col-12': labelPage != 'Tambah',
                    }"
                  >
                    <label>Nilai Jual :</label>
                    <div class="input-group">
                      <div class="input-group-prepend">
                        <span class="input-group-text">Rp</span>
                      </div>
                      <input
                        type="text"
                        class="form-control text-right"
                        v-model.lazy="formData.nilai_jual"
                        @keyup="formChange('nilai_jual')"
                        :disabled="validated == 1 && labelPage == 'Detail'"
                        :class="{
                          'is-invalid': formError && formError.nilai_jual,
                        }"
                        v-money="configMoney"
                      />
                    </div>
                  </div>
                </div>

                <!-- <div class="form-group">
                  <label>Beban Akun :</label>
                  <Select2
                    :disabled="validated == 1 && labelPage == 'Detail'"
                    v-model="formData.akun_beban"
                    :options="optionAccount"
                    :settings="{ templateResult: formatState }"
                    placeholder="Beban Akun"
                    @change="formChange('akun_beban')"
                    @select="formChange('akun_beban')"
                    :class="{
                      'is-invalid': formError && formError.akun_beban,
                    }"
                  />
                </div> -->

                <div class="form-group">
                  <label>Akun Bank :</label>
                  <Select2
                    :disabled="validated == 1 && labelPage == 'Detail'"
                    v-model="formData.akun_bank"
                    :options="OptionAccountBank"
                    :settings="{ templateResult: formatState }"
                    placeholder="Akun Bank"
                    @change="formChange('akun_bank')"
                    @select="formChange('akun_bank')"
                    :class="{
                      'is-invalid': formError && formError.akun_bank,
                    }"
                  />
                </div>

                <div class="form-group">
                  <label>Catatan :</label>
                  <textarea
                    :disabled="validated == 1 && labelPage == 'Detail'"
                    type="text"
                    class="form-control"
                    v-model="formData.keterangan"
                    placeholder="Masukkan Catatan"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
      </form>
    </div>
  </div>
</template>

<script>
import SidebarDashboard from "../../../../components/Sidebar.vue";
import NavbarDashboard from "../../../../components/Navbar.vue";
import Select2 from "vue3-select2-component";
import Datepicker from "vue3-date-time-picker";
import { ref } from "vue";

import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";

import $ from "jquery";
import moment from "moment";
import "moment/locale/id";
import {
  checkRules,
  cksClient,
  showAlert,
  checkModuleAccess,
} from "../../../../helper";
import { get_AkunList } from "../../../../actions/akun_perkiraan";
import { VMoney } from "v-money";
import { get_ListClient } from "../../../../actions/client";
import { get_AsetList } from "../../../../actions/aset";
import {
  post_AssetPenjualanSave,
  get_AsetPenjualanDetail,
} from "../../../../actions/aset/penjualan";

// import TableLite from 'vue3-table-lite'
export default {
  name: "dataDepartement",
  components: {
    // FooterDashboard,
    SidebarDashboard,
    NavbarDashboard,
    Select2,
    Datepicker,
    // TableLite
  },

  directives: {
    money: VMoney,
  },

  data() {
    return {
      checkAccess: checkModuleAccess,
      isDetailable: true,
      validated: 1,
      OptionClient: [],
      OptionAccountBank: [],
      OptionAccountStopAset: [],
      optionAccount: [],
      OptionAset: [],
      isSubmit: false,
      id_company: cksClient().get("_account").id_company,
      labelPage: !this.$route.params.id ? "Tambah" : "Detail",
      configMoney: {
        decimal: ",",
        thousands: ".",
        prefix: "",
        suffix: "",
        precision: 0,
        masked: false /* doesn't work with directive */,
      },
      //FORMDATA
      formData: {
        id: this.$route.params.id ? this.$route.params.id : "",
        kode_aset: "",
        tanggal_jual: "",
        aset_nilai: 0,
        id_client: "",
        nilai_jual: "",
        akun_penghentian_aset: "",
        akun_bank: "",
        akun_beban: "",
        keterangan: "",
        status: "aktif",
      },
      formError: [],
      rules: {
        kode_aset: {
          required: true,
        },
        tanggal_jual: {
          required: true,
        },
        nilai_jual: {
          required: true,
        },
        akun_penghentian_aset: {
          required: true,
        },
        akun_bank: {
          required: true,
        },
        keterangan: {
          required: false,
        },
      },
    };
  },

  setup() {
    const date = ref(new Date());

    // In case of a range picker, you'll receive [Date, Date]
    const format = (date) => {
      const month = [
        "Januari",
        "Februari",
        "Maret",
        "April",
        "Mei",
        "Juni",
        "Juli",
        "Agustus",
        "September",
        "Oktober",
        "November",
        "Desember",
      ];
      const day = date.getDate();
      const monthText = month[date.getMonth()];
      const year = date.getFullYear();

      return `${day} ${monthText} ${year}`;
    };
    return {
      date,
      format,
    };
  },

  created() {
    if (!this.checkAccess("asset", "cru_check_asset")) {
      // this.validated = 3;
      this.isDetailable = false;
    }
    this.getAkun("14,15", "OptionAccountStopAset");
    this.getAkun("1", "OptionAccountBank");
    this.getAkun("13,14,14", "optionAccount");
    this.getAset();
    this.getClient();
    if (this.labelPage == "Detail") {
      this.isDetailable = false;
      this.getData();
    }
  },

  methods: {
    editable() {
      this.validated = 2;
    },
    addHasil() {
      this.$router.push({
        name: "InsertResultAssetCheck",
        params: {
          kode: this.formData.kode,
        },
      });
    },

    formatMoney(a) {
      if (!a) {
        return 0;
      }
      return a.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },

    getData() {
      get_AsetPenjualanDetail(
        { id: this.formData.id },
        (res) => {
          var { data } = res;
          delete data.created_at;
          delete data.updated_at;
          data.tanggal_jual = data.tanggal_jual
            ? moment(data.tanggal_jual).format("YYYY-MM-DD")
            : "";
          data.nilai_jual = data.nilai_jual
            ? this.formatMoney(data.nilai_jual)
            : "";
          this.formData = data;
        },
        () => {
          showAlert(this.$swal, {
            title: "GAGAL!",
            msg: "Data tidak ditemukan",
            showCancelButton: true,
            showConfirmButton: false,
            onCancel: () => {
              this.$router.push({
                name: "AssetSelling",
              });
            },
          });
        }
      );
    },
    //MASTER
    getAkun(idTipe, params) {
      get_AkunList(
        {
          id_company: this.id_company,
          tipe: idTipe,
        },
        (res) => {
          const { list } = res;
          this[params] = [];
          for (const key in list) {
            if (Object.hasOwnProperty.call(list, key)) {
              const element = list[key];
              this[params].push({
                id: element.id,
                text: `${element.nama}`,
                no: element.id,
                type: element.tipe,
                sub_level: parseInt(element.sub_level),
                disabled:
                  parseInt(element.max_subLevel) === parseInt(element.sub_level)
                    ? false
                    : true,
              });
            }
          }
        }
      );
    },
    getAset() {
      get_AsetList(
        {
          id_company: this.id_company,
          search: "aktif",
        },
        (res) => {
          const { list } = res;
          this.OptionAset = [];
          for (const key in list) {
            if (Object.hasOwnProperty.call(list, key)) {
              const element = list[key];
              this.OptionAset.push({
                id: element.kode,
                text: element.nama,
                nilai: element.nilai,
              });
            }
          }
        }
      );
    },
    getClient() {
      get_ListClient(
        {
          id_company: this.id_company,
          search: "aktif",
        },
        (res) => {
          var { list } = res;
          var data = [];
          for (const key in list) {
            if (Object.hasOwnProperty.call(list, key)) {
              const element = list[key];
              data.push({
                id: element.id,
                text: element.nama,
              });
            }
          }
          this.OptionClient = data;
        }
      );
    },
    formatState(state) {
      if (!state.id) {
        return state.text;
      }
      var padding =
        state.sub_level == 2 ? "pl-4" : state.sub_level == 1 ? "pl-3" : "";
      var $state = $(
        '<div class="' +
          padding +
          '">' +
          state.text +
          '<div class="noEstimated" style="font-size: 12px;color: #898989; display:flex; justify-content: space-between;">' +
          state.no +
          "<div>" +
          state.type +
          "</div></div></div>"
      );
      return $state;
    },
    changeDate(data, key) {
      this.formData[key] = moment(data).format("YYYY-MM-DD");
      this.formChange(key);
    },
    async formChange(key, val = "") {
      if (this.rules[key]) {
        this.rules[key].changed = true;
      }
      if (key == "kode_aset") {
        this.formData.aset_nilai =
          val && val.nilai ? this.formatMoney(val.nilai) : 0;
      }
      var check = await checkRules(this.rules, this.formData);
      this.formError = check.error;
    },

    postData() {
      this.isSubmit = true;
      post_AssetPenjualanSave(
        this.formData,
        (res) => {
          this.isSubmit = false;
          var msg =
            res.response_code === 201
              ? "Penjualan aset berhasil dicatat"
              : "Penjualan aset berhasil diperbarui";
          showAlert(this.$swal, {
            title: "BERHASIL!",
            msg: msg,
            onSubmit: () => {
              this.$router.push({
                name: "AssetSelling",
              });
            },
            onDismiss: () => {
              this.$router.push({
                name: "AssetSelling",
              });
            },
          });
        },
        () => {
          this.isSubmit = false;
          showAlert(this.$swal, {
            title: "GAGAL!",
            msg: "Terjadi kesalahan, silakan ulangi kembali",
            showCancelButton: true,
            showConfirmButton: false,
          });
        }
      );
    },

    async onSubmit() {
      for (const key in this.rules) {
        if (Object.hasOwnProperty.call(this.rules, key)) {
          this.rules[key].changed = true;
        }
      }
      var check = await checkRules(this.rules, this.formData);
      this.formError = check.error;
      if (check.success) {
        showAlert(this.$swal, {
          title: "PERHATIAN!",
          msg: this.formData.kode
            ? "Apakah anda yakin, Tindakan ini akan menyimpan semua perubahan yang anda lakukan?"
            : "Apakah anda yakin, semua data yang telah anda isikan akan tersimpan?",
          showCancelButton: true,
          cancelButtonText: "Batal",
          confirmButtonText: "Ya, Lanjutkan",
          onSubmit: () => {
            this.postData();
          },
        });
      }
    },
  },
};
</script>

<style scoped src="../../../../assets/css/style.css"></style>

<style scoped src="../../../../assets/css/custom.css"></style>

<style scoped>
.card .card-title {
  font-size: 18px;
  color: #000;
  font-weight: 500;
}

.info-detail .title {
  font-size: 18px;
  font-weight: 500;
}

.info-detail .sub-title {
  color: #c6c6c6;
  font-size: 12px;
  font-weight: 400;
  margin-top: 10px;
}
</style>
