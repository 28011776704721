import { API } from "../../constans/Url"
import { apiClient } from "../../helper"

const get_AsetList = (params, callbackSuccess, callbackError) => {
  apiClient(true).get(API.ASET.LIST, params).then(res => {
    if (res.status === 200) {
      callbackSuccess(res.data)
    } else {
      callbackError(res.data)
    }
  }).catch(err => {
    callbackError(err)
  })
}


const get_MetodePenyustanList = (callbackSuccess, callbackError) => {
  apiClient(true).get(API.ASET.PENYUSUTAN.LIST).then(res => {
    if (res.status === 200) {
      callbackSuccess(res.data)
    } else {
      callbackError(res.data)
    }
  }).catch(err => {
    callbackError(err)
  })
}

const post_AssetSave = (params, callbackSuccess, callbackError) => {
  apiClient(true).post(API.ASET.SAVE, params).then(res => {
    if (res.status === 200 || res.status === 201) {
      callbackSuccess(res.data)
    } else {
      callbackError(res.data)
    }
  }).catch(err => {
    callbackError(err)
  })
}

const get_AsetDetail = (kode, callbackSuccess, callbackError) => {
  apiClient(true).get(API.ASET.GET, {kode: kode}).then(res => {
    if (res.status === 200) {
      callbackSuccess(res.data)
    } else {
      callbackError(res.data)
    }
  }).catch(err => {
    callbackError(err)
  })
}

const post_AssetLokasiSave = (params, callbackSuccess, callbackError) => {
  apiClient(true).post(API.ASET.LOKASI.SAVE, params).then(res => {
    if (res.status === 200 || res.status === 201) {
      callbackSuccess(res.data)
    } else {
      callbackError(res.data)
    }
  }).catch(err => {
    callbackError(err)
  })
}


const post_AssetSavePembayaran = (params, callbackSuccess, callbackError) => {
  apiClient(true).post(API.ASET.PEMBAYARAN.SAVE_PEMBAYARAN, params).then(res => {
    if (res.status === 200 || res.status == 201) {
      callbackSuccess(res.data)
    } else {
      callbackError(res.data)
    }
  }).catch(err => {
    callbackError(err)
  })
}

const post_AssetJurnalBack = (params, callbackSuccess, callbackError) => {
  apiClient(true).post(API.ASET.PEMBAYARAN.JURNAL_BACK, params).then(res => {
    if (res.status === 200 || res.status == 201) {
      callbackSuccess(res.data)
    } else {
      callbackError(res.data)
    }
  }).catch(err => {
    callbackError(err)
  })
}

export {
  get_AsetList,
  get_MetodePenyustanList,
  post_AssetSave,
  get_AsetDetail,
  post_AssetLokasiSave,
  post_AssetSavePembayaran,
  post_AssetJurnalBack
}